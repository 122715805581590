<template>
  <div>
    <div class="nav-title">Account</div>
    <ul class="nav">
      <li class="nav-item">
        <router-link
          :to="{ name: 'dashboard', params: { accountType } }"
          class="flex flex-row items-center text-xs"
        >
          <ion-icon name="grid-outline" class="text-12 mr-4" />
          <span>Dashboard</span>
        </router-link>
      </li>
    </ul>

    <!-- Start of Finance -->
    <template
      v-if="
        canAccessAny([
          'activities-recent',
          'loans-requests',
          'settlements',
          'settlements-due',
          'repayments',
          'repayments-due',
          'cards',
          'cards-request',
          'cards-transaction',
          'repayments-statistics',
          'payments-credit-cards',
          'repayments-credit-cards',
          'repayments-business',
          'repayments-defaulters',
          'disbursement-personal-loans',
        ])
      "
    >
      <div class="nav-title">Finance</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccessAny(['users-highend'])">
          <router-link
            :to="{ name: 'users-highend' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="star-half-outline" class="text-12 mr-4" />
            <span>Lead Qualification</span>
          </router-link>
        </li>
        <!-- beginning of cards -->

        <li class="nav-item" v-if="canAccessAny(['cards', 'cards-request'])">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.cards)"
          >
            <ion-icon name="card-outline" class="text-12 mr-4" />
            <span>Cards</span>
          </a>
          <ul class="sub-nav collapse" ref="cards">
            <li v-if="canAccess('cards')">
              <router-link
                :to="{ name: 'cards', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>All Cards</span>
              </router-link>
            </li>
            <li v-if="canAccess('cards-request')">
              <router-link
                :to="{ name: 'cards-request', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Card Requests</span>
              </router-link>
            </li>
            <li v-if="canAccess('cards-transaction')">
              <router-link
                :to="{ name: 'cards-transaction', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Transactions</span>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- end of cards -->

        <li class="nav-item" v-if="canAccessAny(['cards', 'cards-request'])">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.virtualcards)"
          >
            <ion-icon name="card-outline" class="text-12 mr-4" />
            <span>Virtual Card</span>
          </a>
          <ul class="sub-nav collapse" ref="virtualcards">
            <li v-if="canAccess('cards')">
              <router-link
                :to="{
                  name: 'virtual-cards',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Transactions</span>
              </router-link>
            </li>
            <li v-if="canAccess('cards-request')">
              <router-link
                :to="{ name: 'virtual-cards-cards', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Cards</span>
              </router-link>
            </li>
            <li v-if="canAccess('cards-transaction')">
              <router-link
                :to="{ name: 'virtual-cards-users', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Users</span>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- end of cards -->
        <li
          class="nav-item"
          v-if="
            canAccessAny([
              'repayments',
              'repayments-due',
              'repayments-credit-cards',
            ])
          "
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.repayments)"
          >
            <ion-icon name="sync-outline" class="text-12 mr-4" />
            <span>Repayments</span>
          </a>
          <ul class="sub-nav collapse" ref="repayments">
            <li v-if="canAccess('repayments-statistics')">
              <router-link
                :to="{ name: 'repayments-statistics', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Repayments Statistics</span>
              </router-link>
            </li>
            <li v-if="canAccess('repayments')">
              <router-link
                :to="{ name: 'repayments', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Loan Repayments</span>
              </router-link>
            </li>
            <li v-if="canAccess('repayments')">
              <router-link
                :to="{ name: 'advancly-loans', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Advancly Loans</span>
              </router-link>
            </li>
            <li v-if="canAccess('payments-credit-cards')">
              <router-link
                :to="{ name: 'payments-credit-cards', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Credit Card Payments</span>
              </router-link>
            </li>
            <li v-if="canAccess('repayments-credit-cards')">
              <router-link
                :to="{
                  name: 'repayments-credit-cards',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Credit Card Repayments</span>
              </router-link>
            </li>
            <li v-if="canAccess('repayments-business')">
              <router-link
                :to="{ name: 'repayments-business', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Business Repayments</span>
              </router-link>
            </li>
            <li v-if="canAccess('settle-repayments')">
              <router-link
                :to="{ name: 'settle-repayments', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Settle Repayments</span>
              </router-link>
            </li>
            <li v-if="canAccess('repayments-defaulters')">
              <router-link
                :to="{ name: 'repayments-defaulters', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Defaulters</span>
              </router-link>
            </li>
            <li v-if="canAccess('disbursement-personal-loans')">
              <a
                href="#!"
                class="flex flex-row items-center text-xs"
                @click.prevent="collapse($refs.lendersRepayment)"
              >
                <span class="mr-3">-</span>
                <span>Lenders</span>
              </a>
              <ul class="sub-nav collapse" ref="lendersRepayment">
                <li v-if="canAccess('disbursement-personal-loans')">
                  <a
                    href="#!"
                    class="flex flex-row items-center text-xs"
                    @click.prevent="collapse($refs.lendersLoanRepayment)"
                  >
                    <span class="mr-3">-</span>
                    <span>Personal Loans</span>
                  </a>
                  <ul class="sub-nav collapse" ref="lendersLoanRepayment">
                    <li v-if="canAccess('disbursement-personal-loans')">
                      <router-link
                        :to="{
                          name: 'tangerine-loan-repayments',
                          params: { accountType },
                        }"
                        class="flex flex-row items-center text-xs"
                      >
                        <span class="mr-3">-</span>
                        <span>Tangerine Life</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li v-if="canAccess('disbursement-personal-loans')">
                  <a
                    href="#!"
                    class="flex flex-row items-center text-xs"
                    @click.prevent="collapse($refs.lendersCreditCardRepayment)"
                  >
                    <span class="mr-3">-</span>
                    <span>Credit Card Loans</span>
                  </a>
                  <ul class="sub-nav collapse" ref="lendersCreditCardRepayment">
                    <li v-if="canAccess('disbursement-personal-loans')">
                      <router-link
                        :to="{
                          name: 'tangerine-credit-card-repayments',
                          params: { accountType },
                        }"
                        class="flex flex-row items-center text-xs"
                      >
                        <span class="mr-3">-</span>
                        <span>Tangerine Life</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          v-if="
            canAccessAny([
              'disbursement-personal-loans',
              'disbursement-credit-cards',
              'disbursement-business-loans',
              'disbursement-statistics',
              'disbursement-credit-card-loans',
              'bills-transactions',
            ])
          "
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.disbursement)"
          >
            <ion-icon name="cash-outline" class="text-12 mr-4" />
            <span>Disbursement</span>
          </a>
          <ul class="sub-nav collapse" ref="disbursement">
            <li v-if="canAccess('disbursement-personal-loans')">
              <router-link
                :to="{
                  name: 'disbursement-personal-loans',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Personal Loans</span>
              </router-link>
            </li>
            <li v-if="canAccess('disbursement-credit-card-loans')">
              <router-link
                :to="{
                  name: 'disbursement-credit-card-loans',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Credit Card Loans</span>
              </router-link>
            </li>
            <li v-if="canAccess('disbursement-business-loans')">
              <router-link
                :to="{
                  name: 'disbursement-business-loans',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Business Loans</span>
              </router-link>
            </li>
            <li v-if="canAccess('disbursement-personal-loans')">
              <router-link
                :to="{
                  name: 'affiliate-settlement',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Affiliates</span>
              </router-link>
            </li>
            <li v-if="canAccess('disbursement-statistics')">
              <router-link
                :to="{
                  name: 'disbursement-statistics',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Statistics</span>
              </router-link>
            </li>
            <li v-if="canAccess('disbursement-personal-loans')">
              <a
                href="#!"
                class="flex flex-row items-center text-xs"
                @click.prevent="collapse($refs.lendersDisbursement)"
              >
                <span class="mr-3">-</span>
                <span>Lenders</span>
              </a>
              <ul class="sub-nav collapse" ref="lendersDisbursement">
                <li v-if="canAccess('disbursement-personal-loans')">
                  <a
                    href="#!"
                    class="flex flex-row items-center text-xs"
                    @click.prevent="collapse($refs.lendersLoanDisbursement)"
                  >
                    <span class="mr-3">-</span>
                    <span>Personal Loans</span>
                  </a>
                  <ul class="sub-nav collapse" ref="lendersLoanDisbursement">
                    <li v-if="canAccess('disbursement-personal-loans')">
                      <router-link
                        :to="{
                          name: 'tangerine-personal-loans',
                          params: { accountType },
                        }"
                        class="flex flex-row items-center text-xs"
                      >
                        <span class="mr-3">-</span>
                        <span>Tangerine Life</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li v-if="canAccess('disbursement-personal-loans')">
                  <a
                    href="#!"
                    class="flex flex-row items-center text-xs"
                    @click.prevent="
                      collapse($refs.lendersCreditCardDisbursement)
                    "
                  >
                    <span class="mr-3">-</span>
                    <span>Credit Card Loans</span>
                  </a>
                  <ul
                    class="sub-nav collapse"
                    ref="lendersCreditCardDisbursement"
                  >
                    <li v-if="canAccess('disbursement-personal-loans')">
                      <router-link
                        :to="{
                          name: 'tangerine-credit-card-disbursements',
                          params: { accountType },
                        }"
                        class="flex flex-row items-center text-xs"
                      >
                        <span class="mr-3">-</span>
                        <span>Tangerine Life</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccess('bills-transactions')">
          <router-link
            :to="{ name: 'bills-transactions', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="reader-outline" class="text-12 mr-4" />
            <span>Bills Transactions</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('bills-transactions')">
          <router-link
            :to="{ name: 'trips', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="airplane-outline" class="text-12 mr-4" />
            <span>Trips</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'reconciliation', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="grid-outline" class="text-12 mr-4" />
            <span>Reconciliation</span>
          </router-link>
        </li>
        <li v-if="canAccess('refunds')" class="nav-item">
          <router-link
            :to="{ name: 'refunds', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="cash-outline" class="text-12 mr-4" />
            <span>Refunds</span>
          </router-link>
        </li>

        <!-- Start of Cash -->
        <li class="nav-item" v-if="canAccess('cash-transactions')">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.cashTransactions)"
          >
            <ion-icon name="card-outline" class="text-12 mr-4" />
            <span>Cash</span>
          </a>
          <ul class="sub-nav collapse" ref="cashTransactions">
            <li v-if="canAccess('cash-transactions')">
              <router-link
                :to="{ name: 'cash-transactions', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Transactions</span>
              </router-link>
            </li>
            <li v-if="canAccess('cash-configuration')">
              <router-link
                :to="{ name: 'cash-configuration', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Configuration</span>
              </router-link>
            </li>
            <li v-if="canAccess('cash-wallet-limit-requests')">
              <router-link
                :to="{
                  name: 'cash-wallet-limit-requests',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Limit Requests</span>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- End of Cash -->

        <!-- Start of virtual cards -->
        <li class="nav-item" v-if="canAccess('virtual-cards')">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.virtualCards)"
          >
            <ion-icon name="card-outline" class="text-12 mr-4" />
            <span>Virtual Cards</span>
          </a>
          <ul class="sub-nav collapse" ref="virtualCards">
            <li v-if="canAccess('virtual-cards')">
              <router-link
                :to="{ name: 'virtual-cards' }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Virtual Cards</span>
              </router-link>
            </li>
            <li v-if="canAccess('virtual-card-transactions')">
              <router-link
                :to="{ name: 'virtual-card-transactions' }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Transactions</span>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- End of virtual cards -->

        <!-- Start of expense transactions & configuration -->
        <li class="nav-item" v-if="canAccess('expense-transactions')">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.expenseTransactions)"
          >
            <ion-icon name="card-outline" class="text-12 mr-4" />
            <span>Expense Transactions</span>
          </a>
          <ul class="sub-nav collapse" ref="expenseTransactions">
            <li v-if="canAccess('expense-transactions')">
              <router-link
                :to="{ name: 'expense-transactions', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Transactions</span>
              </router-link>
            </li>
            <li v-if="canAccess('expense-configuration')">
              <router-link
                :to="{ name: 'expense-configuration', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span class="mr-3">-</span>
                <span>Configuration</span>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- End of Start of expense transactions & configuration -->

        <!-- Start of savings -->
        <li class="nav-item" v-if="canAccess('dollar-savings')">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.savingsRef)"
          >
            <ion-icon name="card-outline" class="text-12 mr-4" />
            <span>Savings</span>
          </a>
          <ul class="sub-nav collapse" ref="savingsRef">
            <li class="nav-item" v-if="canAccess('dollar-savings')">
              <router-link
                :to="{ name: 'dollar-savings', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="business-outline" class="text-12 mr-4" />
                <span>Dollar Savings</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('flexi-savings')">
              <router-link
                :to="{ name: 'flexi-savings', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="business-outline" class="text-12 mr-4" />
                <span>Flexi Savings</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('vault-savings')">
              <router-link
                :to="{ name: 'vault-savings', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="briefcase-outline" class="text-12 mr-4" />
                <span>Vault Savings</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="canAccess('savings-configuration')">
              <router-link
                :to="{ name: 'savings-configuration', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="business-outline" class="text-12 mr-4" />
                <span>Configuration</span>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- End of savings -->
        <li class="nav-item" v-if="canAccess('incoming-wema-transactions')">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.wemaTransactionsRef)"
          >
            <ion-icon name="wallet-outline" class="text-12 mr-4" />
            <span>Wema Transactions</span>
          </a>
          <ul class="sub-nav collapse" ref="wemaTransactionsRef">
            <li class="nav-item" v-if="canAccess('incoming-wema-transactions')">
              <router-link
                :to="{
                  name: 'incoming-wema-transactions',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="swap-horizontal-outline" class="text-12 mr-4" />
                <span>Incoming Transactions</span>
              </router-link>
            </li>

            <li v-if="canAccess('outgoing-wema-transactions')">
              <router-link
                :to="{
                  name: 'outgoing-wema-transactions',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="swap-horizontal-outline" class="text-12 mr-4" />
                <span>Outgoing Transactions</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </template>
    <!-- End of Finance -->

    <template
      v-if="
        canAccessAny([
          'users',
          'users-pre-approved',
          'users-merchant-pre-approved',
          'users-approved',
          'users-incomplete',
          'users-declined',
        ])
      "
    >
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('users')">
          <router-link
            :to="{ name: 'users' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>All Users</span>
          </router-link>
        </li>

        <li
          class="nav-item"
          v-if="
            canAccessAny([
              'users-pre-approved',
              'users-merchant-pre-approved',
              'users-approved',
              'users-incomplete',
              'users-declined',
              'users-limit-increase-request',
              'doc-processor',
            ])
          "
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.utopia)"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Processor (UTOPIA)</span>
          </a>
          <ul class="sub-nav collapse" ref="utopia">
            <li class="nav-item" v-if="canAccess('users-approved')">
              <router-link
                :to="{ name: 'users-process', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Processing</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="canAccess('doc-processor')">
              <router-link
                :to="{ name: 'doc-processor', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Doc Processor</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="canAccess('users-approved')">
              <router-link
                :to="{ name: 'users-approved', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Processed</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="canAccess('users-pre-approved')">
              <router-link
                :to="{ name: 'users-pre-approved', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Pre-Approvals</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="canAccess('users-merchant-pre-approved')"
            >
              <router-link
                :to="{
                  name: 'users-merchant-pre-approved',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span>Merchant</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="canAccess('users-limit-increase-request')"
            >
              <router-link
                :to="{
                  name: 'users-limit-increase-request',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span>Limit Increase Requests</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="canAccess('users-incomplete')">
              <router-link
                :to="{ name: 'users-incomplete', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Incomplete</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('users-declined')">
              <router-link
                :to="{ name: 'users-declined' }"
                class="flex flex-row items-center text-xs"
              >
                <span>Declined</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccess('users')">
          <router-link
            :to="{ name: 'users-post-no-debit' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Users On PND</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('users')">
          <router-link
            :to="{ name: 'users-appeal-requests' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Appeal Requests</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="canAccessAny(['rejected-docs', 'new-docs'])">
      <div class="nav-title">Doc Processor</div>
      <ul class="nav">
        <li class="nav-item">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs['doc-processor'])"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Doc Processor</span>
          </a>
          <ul class="sub-nav collapse" ref="doc-processor">
            <li class="nav-item">
              <router-link
                :to="{ name: 'loan-processor', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Loan Processor</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'rejected-docs', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Rejected Docs</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'new-docs', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>New Docs</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </template>

    <template
      v-if="
        canAccessAny([
          'users-processing',
          'loans',
          'loans-business',
          'loans-requests',
          'companies-credit',
          'underwriters-statistics',
          'identity-verifications',
          'loanbot-verifications',
          'bank-accounts',
          'bank-accounts-pending',
        ])
      "
    >
      <div class="nav-title">underwriting</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('loans-requests')">
          <router-link
            :to="{ name: 'loans-requests', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="document-text-outline" class="text-12 mr-4" />
            <span>Loan Requests</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('loans')">
          <router-link
            :to="{ name: 'loans', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="document-text-outline" class="text-12 mr-4" />
            <span>All Loans</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template
      v-if="
        canAccessAny([
          'companies',
          'companies-new',
          'companies-requests',
          'admins',
          'admins-new',
          'users',
          'users-approved',
          'users',
          'users-paid',
          'users-active',
          'users-inactive',
          'users-active-inactive-statistics',
        ])
      "
    >
      <div class="nav-title">Customers</div>
      <ul
        class="nav"
        v-if="
          canAccessAny([
            'companies',
            'companies-new',
            'companies-requests',
            'users',
            'users-approved',
          ])
        "
      >
        <li class="nav-item" v-if="canAccess('users-approved')">
          <router-link
            :to="{ name: 'users-approved', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Approved customers</span>
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="
            canAccessAny([
              'users',
              'users-paid',
              'users-active',
              'users-inactive',
            ])
          "
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.employedCustomers)"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Employed</span>
          </a>
          <ul class="sub-nav collapse" ref="employedCustomers">
            <li class="nav-item" v-if="canAccess('users-active')">
              <router-link
                :to="{ name: 'users-active', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Active Customers</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('users-inactive')">
              <router-link
                :to="{ name: 'users-inactive', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Inactive Customers</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('users-paid')">
              <router-link
                :to="{ name: 'users-paid', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Paid Customers</span>
              </router-link>
            </li>
          </ul>
        </li>

        <!-- <li
          class="nav-item"
          v-if="
            canAccessAny(['companies', 'companies-new', 'companies-requests'])
          "
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.selfEmployed)"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Self Employed</span>
          </a>
          <ul class="sub-nav collapse" ref="selfEmployed">
            <li class="nav-item" v-if="canAccess('users')">
              <router-link
                :to="{ name: 'users', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Active Customers</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('users')">
              <router-link
                :to="{ name: 'users', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Inactive Customers</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('users')">
              <router-link
                :to="{ name: 'users', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Paid Customers</span>
              </router-link>
            </li>
          </ul>
        </li> -->

        <li
          class="nav-item"
          v-if="
            canAccessAny(['companies', 'companies-new', 'companies-requests'])
          "
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.businessCustomers)"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Business</span>
          </a>
          <ul class="sub-nav collapse" ref="businessCustomers">
            <li class="nav-item" v-if="canAccess('companies')">
              <router-link
                :to="{ name: 'companies', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Active Business</span>
              </router-link>
            </li>
            <!-- <li class="nav-item" v-if="canAccess('users')">
              <router-link
                :to="{ name: 'users', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Paid Customers</span>
              </router-link>
            </li> -->
          </ul>
        </li>
        <li
          class="nav-item"
          v-if="canAccess('users-active-inactive-statistics')"
        >
          <router-link
            :to="{
              name: 'users-active-inactive-statistics',
              params: { accountType },
            }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="people-outline" class="text-12 mr-4" />
            <span>Statistics</span>
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="canAccess('users-active-inactive-statistics')"
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.shareAndEarn)"
          >
            <ion-icon name="people-outline" class="text-12 mr-4" />
            <span>Share and Earn</span>
          </a>
          <ul class="sub-nav collapse" ref="shareAndEarn">
            <li
              class="nav-item"
              v-if="canAccess('users-active-inactive-statistics')"
            >
              <router-link
                :to="{
                  name: 'referred-users',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span>Referred Users</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="canAccess('users-active-inactive-statistics')"
            >
              <router-link
                :to="{
                  name: 'users-referral-wallet',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <span>Users Wallet</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          v-if="canAccessAny(['decline-users', 'secure-card'])"
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.secureCards)"
          >
            <ion-icon name="people-outline" class="text-12 mr-4" />
            <span>Secured Cards</span>
          </a>
          <ul class="sub-nav collapse" ref="secureCards">
            <li class="nav-item" v-if="canAccess('decline-users')">
              <router-link
                :to="{ name: 'decline-users', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Declined</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('secure-card')">
              <router-link
                :to="{ name: 'secure-card', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Secured Cards</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li v-if="canAccess('communication-templates')" class="nav-item">
          <router-link
            :to="{
              name: 'communication-templates',
              params: { accountType },
            }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="chatbox-ellipses-outline" class="text-12 mr-4" />
            <span>Communication Templates</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="canAccess('push-notifications')">
      <div class="nav-title">Push Notifications</div>
      <ul class="nav">
        <li class="nav-item">
          <router-link
            :to="{ name: 'push-notifications', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Notification Management</span>
          </router-link>
        </li>
      </ul>
    </template>

    <!-- share and earn -->
    <template v-if="canAccessAny(['users'])">
      <div class="nav-title">Share and Earn</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('users')">
          <router-link
            :to="{ name: 'referrers' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Referrers</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('users')">
          <router-link
            :to="{ name: 'referees' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Referees</span>
          </router-link>
        </li>
      </ul>
    </template>
    <!-- end of share and earn -->
    <template
      v-if="
        canAccessAny([
          'companies',
          'companies-new',
          'companies-requests',
          'admins',
          'admins-new',
          'users',
          'users-paid',
          'users-active',
          'users-inactive',
          'users-assigned',
          'users-new',
          'users-fresh',
          'verification-method',
          'bank-statements',
          'lenders',
          'lenders-new',
          'processor',
        ])
      "
    >
      <div class="nav-title">Processing</div>
      <ul
        class="nav"
        v-if="
          canAccessAny(['companies', 'companies-new', 'companies-requests'])
        "
      >
        <li
          class="nav-item"
          v-if="
            canAccessAny([
              'users',
              'users-paid',
              'users-active',
              'users-inactive',
              'users-assigned',
              'users-new',
              'users-fresh',
            ])
          "
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.users)"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Employed</span>
          </a>
          <ul class="sub-nav collapse" ref="users">
            <!-- <li class="nav-item" v-if="canAccess('users-fresh')">
              <router-link
                :to="{ name: 'users-fresh', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>New Users</span>
              </router-link>
            </li> -->
            <li class="nav-item" v-if="canAccess('users')">
              <router-link
                :to="{ name: 'users', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <!-- <ion-icon name="people-outline" class="text-12 mr-4" /> -->
                <span>All Users</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('verification-method')">
              <router-link
                :to="{ name: 'verification-method', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Express Applications</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('bank-statements')">
              <router-link
                :to="{ name: 'bank-statements', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Bank Statement</span>
              </router-link>
            </li>
            <!-- <li class="nav-item" v-if="canAccess('users-paid')">
              <router-link
                :to="{ name: 'users-paid', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Paid Users</span>
              </router-link>
            </li> -->
            <!-- <li class="nav-item" v-if="canAccess('users-active')">
              <router-link
                :to="{ name: 'users-active', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Active Users</span>
              </router-link>
            </li> -->
            <!-- <li class="nav-item" v-if="canAccess('users-approved')">
              <router-link
                :to="{ name: 'users-approved', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Approved customers</span>
              </router-link>
            </li> -->
            <!-- <li class="nav-item" v-if="canAccess('users-inactive')">
              <router-link
                :to="{ name: 'users-inactive', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Inactive Users</span>
              </router-link>
            </li> -->
            <li class="nav-item" v-if="canAccess('users-assigned')">
              <router-link
                :to="{ name: 'users-assigned', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Assigned To Me</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('users-new')">
              <router-link
                :to="{ name: 'users-new', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Create User</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          v-if="
            canAccessAny(['companies', 'companies-new', 'companies-requests'])
          "
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.companies)"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Businesses</span>
          </a>
          <ul class="sub-nav collapse" ref="companies">
            <li class="nav-item" v-if="canAccess('companies-requests')">
              <router-link
                :to="{ name: 'companies-requests', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>New Businesses</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('companies')">
              <router-link
                :to="{ name: 'companies', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>All Business</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('companies-new')">
              <router-link
                :to="{ name: 'companies-new', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <!-- <ion-icon name="add-outline" class="text-12 mr-4" /> -->
                <span>Add Business</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('companies-statistics')">
              <router-link
                :to="{ name: 'companies-statistics', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Statistics</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccessAny(['admins', 'admins-new'])">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.admins)"
          >
            <ion-icon name="people-outline" class="text-12 mr-4" />
            <span>Admins</span>
          </a>
          <ul class="sub-nav collapse" ref="admins">
            <li class="nav-item" v-if="canAccess('admins-new')">
              <router-link
                :to="{ name: 'admins-new', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Add Admin</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('admins')">
              <router-link
                :to="{ name: 'admins', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>All Admins</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccessAny(['lenders', 'lenders-new'])">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.lenders)"
          >
            <ion-icon name="cash-outline" class="text-12 mr-4" />
            <span>Lenders</span>
          </a>
          <ul class="sub-nav collapse" ref="lenders">
            <li class="nav-item" v-if="canAccess('lenders')">
              <router-link
                :to="{ name: 'lenders', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>All Lenders</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('lenders-new')">
              <router-link
                :to="{ name: 'lenders-new', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Add Lender</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccess('processor')">
          <router-link
            :to="{ name: 'processor' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="people-circle-outline" class="text-12 mr-4" />
            <span>CredPal Processor</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template
      v-if="
        canAccessAny([
          'users-awaiting',
          'users-cold',
          'users-dump',
          'users-fresh',
          'users-leads-statistics',
        ])
      "
    >
      <div class="nav-title">Processed</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('users-awaiting')">
          <router-link
            :to="{ name: 'users-awaiting', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="people-outline" class="text-12 mr-4" />
            <span>Awaiting</span>
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="canAccess('users-cold') || canAccess('users-dump')"
        >
          <router-link
            :to="{ name: 'users-cold', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="people-outline" class="text-12 mr-4" />
            <span>Cold / Dump</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('users-leads-statistics')">
          <router-link
            :to="{ name: 'users-leads-statistics', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="people-outline" class="text-12 mr-4" />
            <span>Statistics</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template
      v-if="
        canAccessAny([
          'users-processing',
          'loans',
          'loans-business',
          'loans-requests',
          'companies-credit',
          'underwriters-statistics',
          'identity-verifications',
          'loanbot-verifications',
          'bank-accounts',
          'bank-accounts-pending',
          'verification-method',
          'users-uncertain',
          'users-applications',
        ])
      "
    >
      <div class="nav-title">underwriting</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('users-processing')">
          <router-link
            :to="{ name: 'users-processing', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="document-text-outline" class="text-12 mr-4" />
            <span>Awaiting Processing</span>
          </router-link>
        </li>

        <li class="nav-item" v-if="canAccess('verification-method')">
          <router-link
            :to="{ name: 'verification-method', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="document-text-outline" class="text-12 mr-4" />
            <span>Express Applications</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('loanbot-verifications')">
          <router-link
            :to="{ name: 'loanbot-verifications', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="flash-outline" class="text-12 mr-4" />
            <span>Loanbot Instant Verification</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('users-uncertain')">
          <router-link
            :to="{ name: 'users-uncertain', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="people-outline" class="text-12 mr-4" />
            <span>Uncertain/Pending</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('loans')">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.personalLoans)"
          >
            <ion-icon name="document-text-outline" class="text-12 mr-4" />
            <span>Personal Loans</span>
          </a>
          <ul class="sub-nav collapse" ref="personalLoans">
            <li>
              <router-link
                :to="{ name: 'loans', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>All Loans</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('loans-requests')">
              <router-link
                :to="{ name: 'loans-requests', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Loan Requests</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccess('loans-business')">
          <router-link
            :to="{ name: 'loans-business', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="document-text-outline" class="text-12 mr-4" />
            <span>Business Loans</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('companies-credit')">
          <router-link
            :to="{ name: 'companies-credit', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="cash-outline" class="text-12 mr-4" />
            <span>Business Credit</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('identity-verifications')">
          <router-link
            :to="{ name: 'identity-verifications', params: { accountType } }"
            class="red flex flex-row items-center text-xs"
          >
            <ion-icon name="finger-print-outline" class="text-12 mr-4" />
            <span>Identity Verification</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('loans')">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.bankAccountVerification)"
          >
            <ion-icon name="checkmark-done-outline" class="text-12 mr-4" />
            <span>Bank Account Verification</span>
          </a>
          <ul class="sub-nav collapse" ref="bankAccountVerification">
            <li v-if="canAccess('bank-accounts')">
              <router-link
                :to="{ name: 'bank-accounts', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Complete Verifications</span>
              </router-link>
            </li>
            <li v-if="canAccess('bank-accounts-pending')">
              <router-link
                :to="{ name: 'bank-accounts-pending', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Pending Verifications</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccess('underwriters-statistics')">
          <router-link
            :to="{ name: 'underwriters-statistics', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="bar-chart-outline" class="text-12 mr-4" />
            <span>Statistics</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="canAccess('users-processing')">
      <div class="nav-title">Affiliate</div>
      <ul class="nav">
        <li
          class="nav-item"
          v-if="canAccessAny(['all-affiliate-users', 'users-new-affiliate'])"
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.affiliateUsers)"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Users</span>
          </a>
          <ul class="sub-nav collapse" ref="affiliateUsers">
            <li class="nav-item" v-if="canAccess('all-affiliate-users')">
              <router-link
                :to="{ name: 'all-affiliate-users', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <!-- <ion-icon name="people-outline" class="text-12 mr-4" /> -->
                <span>All Affiliate Users</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('users-new-affiliate')">
              <router-link
                :to="{ name: 'users-new-affiliate', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <!-- <ion-icon name="people-outline" class="text-12 mr-4" /> -->
                <span>Create Affiliate User</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccess('referral-page')">
          <router-link
            :to="{ name: 'referral-page', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="briefcase-outline" class="text-12 mr-4" />
            <span>Referred Users</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('affiliate-statistics')">
          <router-link
            :to="{ name: 'affiliate-statistics', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="cash-outline" class="text-12 mr-4" />
            <span>Statistics</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('affiliate-leads')">
          <router-link
            :to="{ name: 'affiliate-leads', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="alert-circle-outline" class="text-12 mr-4" />
            <span>Leads</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="canAccessAny(['merchants'])">
      <div class="nav-title">Merchant</div>
      <ul class="nav">
        <li
          class="nav-item"
          v-if="canAccessAny(['merchants', 'merchant-category'])"
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.merchantUsers)"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Users</span>
          </a>
          <ul class="sub-nav collapse" ref="merchantUsers">
            <li class="nav-item" v-if="canAccess('merchants')">
              <router-link
                :to="{ name: 'merchants', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>All Merchants</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('merchant-pending')">
              <router-link
                :to="{ name: 'merchant-pending', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Awaiting Approval</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('new-merchant')">
              <router-link
                :to="{ name: 'new-merchant', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <span>Create Merchant</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          v-if="canAccessAny(['merchants', 'merchant-category'])"
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.orders)"
          >
            <ion-icon name="card-outline" class="text-12 mr-4" />
            <span>Orders</span>
          </a>
          <ul class="sub-nav collapse" ref="orders">
            <li class="nav-item" v-if="canAccess('merchant-transaction')">
              <router-link
                :to="{ name: 'merchant-transaction', params: { accountType } }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="wallet-outline" class="text-12 mr-4" />
                <span>Completed Orders</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="canAccess('merchant-incomplete-transaction')"
            >
              <router-link
                :to="{
                  name: 'merchant-incomplete-transaction',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="wallet-outline" class="text-12 mr-4" />
                <span>Incomplete Orders</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('merchant-anonymous-orders')">
              <router-link
                :to="{
                  name: 'merchant-anonymous-orders',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="wallet-outline" class="text-12 mr-4" />
                <span>Anonymous Orders</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="canAccess('merchant-category')">
          <router-link
            :to="{ name: 'merchant-category', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Industry</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('merchant-settlements')">
          <router-link
            :to="{ name: 'merchant-settlements', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Settlements</span>
          </router-link>
        </li>
        <!-- --÷ -->
        <li class="nav-item" v-if="canAccess('merchant-payouts')">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.caas)"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>CAAS</span>
          </a>
          <ul class="sub-nav collapse" ref="caas">
            <li class="nav-item" v-if="canAccess('merchant-payouts')">
              <router-link
                :to="{ name: 'merchant-payouts' }"
                class="flex flex-row items-center text-xs"
              >
                <span>Payouts</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="canAccess('merchant-payouts')">
              <router-link
                :to="{ name: 'merchant-caas-merchants' }"
                class="flex flex-row items-center text-xs"
              >
                <span>Merchants</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="canAccess('merchant-payouts')">
              <router-link
                :to="{ name: 'merchant-spend' }"
                class="flex flex-row items-center text-xs"
              >
                <span>Transactions</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="canAccess('merchant-payouts')">
              <router-link
                :to="{ name: 'merchant-leads' }"
                class="flex flex-row items-center text-xs"
              >
                <span>Leads</span>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- ---÷ -->
        <li class="nav-item" v-if="canAccess('equity-contribution')">
          <router-link
            :to="{ name: 'equity-contribution', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Equity Contributions</span>
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="canAccessAny(['merchants', 'device-financing-orders'])"
        >
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs['device-financing'])"
          >
            <ion-icon name="card-outline" class="text-12 mr-4" />
            <span>Device Financing</span>
          </a>
          <ul class="sub-nav collapse" ref="device-financing">
            <li class="nav-item" v-if="canAccess('device-financing-orders')">
              <router-link
                :to="{
                  name: 'device-financing-orders',
                  params: { accountType },
                }"
                class="flex flex-row items-center text-xs"
              >
                <ion-icon name="wallet-outline" class="text-12 mr-4" />
                <span>Orders</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </template>

    <template
      v-if="canAccessAny(['invest', 'invest-rates', 'invest-configuration'])"
    >
      <div class="nav-title">Invest</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('invest')">
          <router-link
            :to="{ name: 'invest', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Investments</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('invest-configuration')">
          <router-link
            :to="{
              name: 'invest-configuration',
              params: { accountType },
            }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Configuration </span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('tenures-configuration')">
          <router-link
            :to="{
              name: 'tenures-configuration',
              params: { accountType },
            }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Tenures Configuration</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('savings-configuration')">
          <router-link
            :to="{
              name: 'invest-savings-configuration',
              params: { accountType },
            }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business" class="text-12 mr-4" />
            <span>Savings Configuration </span>
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="canAccessAny(['bolt-users', 'bolt-rides'])">
      <div class="nav-title">Bolt Integration</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('bolt-users')">
          <router-link
            :to="{ name: 'bolt-users', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="car-outline" class="text-12 mr-4" />
            <span>Users</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('bolt-rides')">
          <router-link
            :to="{ name: 'bolt-rides', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="person-outline" class="text-12 mr-4" />
            <span>Rides</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="canAccessAny(['promos-airtel'])">
      <div class="nav-title">Promotions</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('promos-airtel')">
          <router-link
            :to="{ name: 'promos-airtel', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="star-half-outline" class="text-12 mr-4" />
            <span>Airtel</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template
      v-if="canAccessAny(['wallets', 'cashbacks', 'wallets-redemption'])"
    >
      <div class="nav-title">Rewards</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('wallets')">
          <router-link
            :to="{ name: 'wallets', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Wallets</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('cashbacks')">
          <router-link
            :to="{ name: 'cashbacks', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Cashbacks Transaction</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('wallets-redemption')">
          <router-link
            :to="{ name: 'wallets-redemption', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="business-outline" class="text-12 mr-4" />
            <span>Wallets Redemption</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template>
      <div class="nav-title">Settings</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('permissions')">
          <router-link
            :to="{ name: 'permissions' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="key-outline" class="text-12 mr-4" />
            <span>Permissions</span>
          </router-link>
        </li>

        <li class="nav-item" v-if="canAccess('permissions')">
          <router-link
            :to="{ name: 'admin' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="key-outline" class="text-12 mr-4" />
            <span>Admin</span>
          </router-link>
        </li>

        <li class="nav-item" v-if="canAccess('permissions')">
          <router-link
            :to="{ name: 'roles' }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="key-outline" class="text-12 mr-4" />
            <span>Roles</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            :to="{ name: 'settings-password', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="lock-closed-outline" class="text-12 mr-4" />
            <span>Change Password</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('settings-configuration')">
          <router-link
            :to="{ name: 'settings-configuration', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="settings-outline" class="text-12 mr-4" />
            <span>Configuration</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('settings-configuration')">
          <router-link
            :to="{ name: 'auth-bypass', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="key" class="text-12 mr-4" />
            <span>Auth Bypass</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="canAccess('settings-configuration')">
          <router-link
            :to="{ name: 'maintenance-mail', params: { accountType } }"
            class="flex flex-row items-center text-xs"
          >
            <ion-icon name="mail" class="text-12 mr-4" />
            <span>Maintenance Notice</span>
          </router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  methods: {
    collapse(element) {
      window.collapse(element, 'toggle');
    },
  },
};
</script>
