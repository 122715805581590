import Vue from 'vue';
import Raphael from 'raphael/raphael';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment';
import NProgress from 'vue-nprogress';
import './components';
import './mixins';
import './assets/sass/app.scss';
import './javascript';
import './slider';

global.Raphael = Raphael;

Vue.use(NProgress);

Vue.config.ignoredElements = [/^ion-/];
Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_ENV !== 'production';

//moment globally
Vue.prototype.$moment = moment;

Vue.prototype.$baseurl = process.env.VUE_APP_API_BASEURL;
// Vue.prototype.$bills_baseurl = process.env.VUE_APP_API_BILLS_BASEURL;

Vue.prototype.$loanbotUrl = process.env.VUE_APP_LOANBOT_BASEURL;
Vue.prototype.$caasUrl = process.env.VUE_APP_CAAS_BASEURL;

const nprogress = new NProgress();

window.App = new Vue({
  nprogress,
  router,
  store,
  ...App,
  methods: App.methods,
}).$mount('#app');
