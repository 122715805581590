import Vue from 'vue';
import VueRouter from 'vue-router';
import middlewares from './middlewares';
import $ from 'jquery';
import pages from '../config/pages';

const RouteProvider = {
  interval: null,
  maxRetries: 50,
  retryCount: 0,
  timeoutDuration: 5000, // 5 seconds timeout

  getRoute(routeName) {
    return pages[routeName];
  },

  getRoutes(namespace) {
    const routes = [];
    Object.entries(pages).forEach(([, page]) => {
      if (page.route.parent && page.route.parent === namespace) {
        routes.push(page.route);
      }
    });
    return routes;
  },

  isStoreReady() {
    return router.app.$store?.state?.session?.ready;
  },

  processRoute(to, from, next) {
    try {
      $('.pre-loader').addClass('hide');

      const route = this.getRoute(to.name);
      if (!route) {
        console.error(`Route not found: ${to.name}`);
        return next({ name: 'errors-404-main' });
      }

      const middlewares = [
        'downloadApp',
        ...(route.middlewares || []),
        'account_type',
        'permissions',
      ];

      const redirect = this.runMiddlewares(to, from, next, middlewares);

      if (redirect !== true) {
        return next(false);
      }

      next();
      router.app.$emit('global', { event: 'navigate', params: [to, from] });
    } catch (error) {
      console.error('Route processing error:', error);
      next({ name: 'errors-500' });
    }
  },

  runMiddlewares(to, from, next, middleware) {
    if (!middleware || !Array.isArray(middleware)) {
      return true;
    }

    try {
      return middleware.every((mdw) => {
        if (!middlewares[mdw]) {
          console.warn(`Middleware not found: ${mdw}`);
          return true;
        }
        return middlewares[mdw].apply(router.app, [to, from, next]);
      });
    } catch (error) {
      console.error('Middleware execution error:', error);
      return false;
    }
  },

  setupRouteTimeout() {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        clearInterval(this.interval);
        reject(new Error('Route processing timeout'));
      }, this.timeoutDuration);

      this.interval = setInterval(() => {
        if (this.isStoreReady() || this.retryCount >= this.maxRetries) {
          clearTimeout(timeout);
          clearInterval(this.interval);
          resolve(this.isStoreReady());
        }
        this.retryCount++;
      }, 100);
    });
  },
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    ...RouteProvider.getRoutes('root'),
    {
      path: '/',
      component: require('@/views/auth/Layout.vue').default,
      children: [...RouteProvider.getRoutes('auth')],
    },

    {
      path: '/',
      component: require('@/views/app/Layout.vue').default,
      children: [...RouteProvider.getRoutes('app')],
    },

    ...RouteProvider.getRoutes('register'),
    ...RouteProvider.getRoutes('errors'),
  ],
});

router.beforeEach(async (to, from, next) => {
  RouteProvider.retryCount = 0;

  try {
    const isStoreReady = await RouteProvider.setupRouteTimeout();

    if (!isStoreReady) {
      console.error('Store failed to initialize');
      return next({ name: 'errors-500' });
    }

    RouteProvider.processRoute(to, from, next);
  } catch (error) {
    console.error('Navigation error:', error);
    next({ name: 'errors-500' });
  }
});

router.afterEach((to) => {
  // Update title with fallback
  document.title = to.meta.title || 'CredPal';

  // Close sidebar if open
  if (router.app.sidebar?.open) {
    router.app.closeSidebar();
  }
});

router.onError((error) => {
  console.error('Router Error:', {
    message: error.message,
    stack: error.stack,
    info: error,
  });

  // Notify error tracking service if available
  if (window.errorTracker) {
    window.errorTracker.captureException(error);
  }
});

export default router;
