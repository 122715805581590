const pages = {
  root: {
    route: {
      name: 'root',
      path: '/',
      parent: 'root',
      component: () => import('@/views/Root.vue'),
      meta: {
        title: 'Corporate Loans',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['*'],
  },

  /** Main Pages */
  ...require('./activities').default,
  ...require('./admins').default,
  ...require('./affiliates').default,
  ...require('./auth').default,
  ...require('./bank').default,
  ...require('./beneficiaries').default,
  ...require('./cards').default,
  ...require('./virtual-cards').default,
  ...require('./companies').default,
  ...require('./disbursements').default,
  ...require('./docs').default,
  ...require('./employees').default,
  ...require('./expenses').default,
  ...require('./groups').default,
  ...require('./investments').default,
  ...require('./loans').default,
  ...require('./merchants').default,
  ...require('./promos-offers').default,
  ...require('./repayments').default,
  ...require('./savings').default,
  ...require('./settings').default,
  ...require('./settlements').default,
  ...require('./subscription').default,
  ...require('./transactions').default,
  ...require('./users').default,
  ...require('./push-notifications').default,
  ...require('./others-pages').default,

  /** Redirects */
  ...require('./redirects').default,
};

export default pages;
