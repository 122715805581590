import { path, admin, corporate } from './utils/pages_utils';

export default {
  cards: {
    route: {
      name: 'cards',
      path: path('cards', '*'),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cards-index' */ '@/views/app/cards/all/Index.vue'
        ),
      meta: {
        title: 'Cards',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_cards',
      'credpal:can_delete_cards',
      'company:can_view_cards',
      'company:can_delete_cards',
      'personal:can_view_cards',
    ],
  },
  'cards-new': {
    route: {
      name: 'cards-new',
      path: path('cards/new', corporate),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cards-new' */ '@/views/app/cards/new/Index.vue'
        ),
      meta: {
        title: 'Create New Card',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['company:can_request_cards'],
  },
  'cards-request': {
    route: {
      name: 'cards-request',
      path: path('cards/request', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cards-request' */ '@/views/app/cards/request/Index.vue'
        ),
      meta: {
        title: 'Request A Cards',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_card_requests',
      'credpal:can_approve_card_requests',
      'credpal:can_decline_card_requests',
    ],
  },
  'virtual-cards': {
    route: {
      name: 'virtual-cards',
      path: path('virtual-cards', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'virtual-cards' */ '@/views/app/virtual-cards/cards/Index.vue'
        ),
      meta: {
        title: 'Virtual Cards',
      },
    },

    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_virtual_cards'],
  },
  'decline-users': {
    route: {
      name: 'decline-users',
      path: path('decline-users', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'users-limit-increase-request' */ '@/views/app/secure-cards/decline-users/Index.vue'
        ),
      meta: {
        title: 'Declined Users',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_decline_users'],
  },
  'secure-card': {
    route: {
      name: 'secure-card',
      path: path('secure-card', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'users-limit-increase-request' */ '@/views/app/secure-cards/secure-cards/Index.vue'
        ),
      meta: {
        title: 'Secure Card',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_secure_cards'],
  },
};
