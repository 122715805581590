export default {
  async all(config = {}) {
    return this.$get({
      url: `${this.$baseurl}/repayments`,
      headers: this.headers,
      ...config,
    });
  },
  async defaults(config = {}) {
    return this.$get({
      url: `${this.$baseurl}/admin/personal/repayments/defaults`,
      headers: this.headers,
      ...config,
    });
  },
  async statistics(query, config = {}) {
    return this.$get({
      url: `${this.$baseurl}/admin/personal/repayments/statistics?${query}`,
      headers: this.headers,
      ...config,
    });
  },
  async settle(repaymentId, config = {}) {
    return this.$post({
      url: `${this.$baseurl}/admin/personal/repayments/${repaymentId}/settle`,
      headers: this.headers,
      ...config,
    });
  },
  async fractionalPayment(repaymentId, config = {}) {
    return this.$post({
      url: `${this.$baseurl}/admin/personal/repayments/${repaymentId}/pay-fraction`,
      headers: this.headers,
      ...config,
    });
  },
  async partialDebit(repaymentId, config = {}) {
    return this.$post({
      url: `${this.$baseurl}/admin/personal/repayments/${repaymentId}/partial-debit`,
      headers: this.headers,
      ...config,
    });
  },
  async getRepaymentWallet(userId, config = {}) {
    return this.$get({
      url: `${this.$baseurl}/admin/personal/repayments/wallet/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async getRepaymentWalletHistory(walletId, config = {}) {
    return this.$get({
      url: `${this.$baseurl}/admin/personal/repayments/wallet/${walletId}/history`,
      headers: this.headers,
      ...config,
    });
  },
  async getActiveUserPlan(userId, config = {}) {
    return this.$get({
      url: `${this.$baseurl}/admin/user-plans/get-active-plan/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async updateRepaymentPercentageOnUserPlan(config = {}) {
    return this.$post({
      url: `${this.$baseurl}/admin/user-plans/update`,
      headers: this.headers,
      ...config,
    });
  },
  async approveRepayment(transactionId, config = {}) {
    return await this.$patch({
      url: `${this.$baseurl}/admin/repayment/transaction/approve/${transactionId}`,
      headers: this.headers,
      ...config,
    });
  },
  async failRepayment(transactionId, config = {}) {
    return await this.$patch({
      url: `${this.$baseurl}/admin/repayment/transaction/reject/${transactionId}`,
      headers: this.headers,
      ...config,
    });
  }
};
