import { path, admin } from './utils/pages_utils';

export default {
  'virtual-cards': {
    route: {
      name: 'virtual-cards',
      path: path('virtual-cards/virtual-cards-transactions', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cards-request' */ '@/views/app/virtual-cards/transactions/Index.vue'
        ),
      meta: {
        title: 'Request A Cards',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_card_requests',
      'credpal:can_approve_card_requests',
      'credpal:can_decline_card_requests',
    ],
  },

  'virtual-cards-cards': {
    route: {
      name: 'virtual-cards-cards',
      path: path('virtual-cards/virtual-cards-cards', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cards-request' */ '@/views/app/virtual-cards/cards/Index.vue'
        ),
      meta: {
        title: 'Request A Cards',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_card_requests',
      'credpal:can_approve_card_requests',
      'credpal:can_decline_card_requests',
    ],
  },
  'virtual-cards-users': {
    route: {
      name: 'virtual-cards-users',
      path: path('virtual-cards/virtual-cards-users', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cards-request' */ '@/views/app/virtual-cards/users/Index.vue'
        ),
      meta: {
        title: 'Request A Cards',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_card_requests',
      'credpal:can_approve_card_requests',
      'credpal:can_decline_card_requests',
    ],
  },
};
