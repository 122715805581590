<template>
  <div
    id="app"
    :class="{
      'sidebar-open': sidebar.open,
      [`${this.$route.name}-page`]: true,
    }"
  >
    <transition>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </transition>

    <nprogress-container />
    <PreLoader />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import events from './config/events';

export default {
  components: {
    NprogressContainer,
    PreLoader: require('@/components/loaders/PreLoader.vue').default,
  },
  data() {
    return {
      sessionInterval: null,
    };
  },
  computed: {
    ...mapState('design', ['sidebar']),
    ...mapState('session', ['expires_at', 'loggedIn']),
  },
  beforeCreate() {
    this.$store.dispatch('session/loadUser');
  },
  async mounted() {
    this.checkSessionExpiry();
    await this.loadSession();
    await this.unreadNotifications();
    this.ready();
    this.setInterval();

    this.loginHandler = () => {
      this.setInterval();
      this.reloadIntercom(this.$store.state.session.user);
    };

    this.globalHandler = (data) => {
      events[data.event]?.apply(this, data.params);
    };

    this.$root.$on('login', this.loginHandler);
    this.$root.$on('global', this.globalHandler);
  },
  beforeDestroy() {
    if (this.sessionInterval) {
      clearInterval(this.sessionInterval);
    }
    this.$root.$off('login', this.loginHandler);
    this.$root.$off('global', this.globalHandler);
  },
  methods: {
    ...mapActions('session', ['logout', 'updateUser']),
    ...mapMutations('design', ['closeSidebar']),
    ...mapMutations('session', ['ready']),

    checkSessionExpiry() {
      const time = this.expires_at;
      if (!time) return;

      const now = new Date().getTime();
      if (now >= time) {
        clearInterval(this.sessionInterval);
        this.handleSessionExpiry();
      }
    },

    handleSessionExpiry() {
      this.logout();
      if (!this.$route.name.match(/login|register|upload-documents/)) {
        this.$router.push({
          name: 'logout',
          query: {
            expired: true,
            redirect: this.$route.fullPath,
          },
        });
      }
    },

    async loadSession() {
      if (!this.loggedIn) return;

      try {
        const response = await this.$get({
          url: `${this.$baseurl}/session`,
          headers: this.headers,
        });

        if (!response?.data?.user) {
          throw new Error('Invalid session response');
        }

        this.updateUser(response.data.user);
        this.$root.$emit('global', {
          event: 'sessionUpdate',
          params: [response.data.user],
        });

        if (response.data.keys) {
          this.updateSessionData(response.data);
        }

        this.reloadIntercom(response.data.user);
        this.handleClosedModals(response.data.closed_modals);
      } catch (error) {
        console.error('Session loading error:', error);
        this.logout();

        if (error.response?.status === 401) {
          this.$router.push({
            name: 'login',
            query: {
              session_expired: true,
              redirect: this.$route.fullPath,
            },
          });
        }
      }
    },

    updateSessionData(data) {
      this.$store.commit('session/updateKeys', data.keys);
      this.$store.commit(
        'session/updatePermissions',
        data.user?.permissions?.map((permission) => permission.slug)
      );
      this.$store.commit('session/setConfiguration', data.configuration);
      this.$store.commit('session/updateRoles', data.user?.roles);
      this.$store.commit(
        'session/updateCorporateCardCount',
        data.corporate_card_count
      );
    },

    handleClosedModals(modals) {
      if (modals?.length) {
        modals.forEach((modal) => {
          this.$store.commit('modals/closeModal', { modal: modal.key });
        });
      }
    },

    setInterval() {
      if (this.sessionInterval) {
        clearInterval(this.sessionInterval);
      }
      this.sessionInterval = setInterval(this.checkSessionExpiry, 30000);
      this.checkSessionExpiry();
    },

    reloadIntercom(user) {
      window.intercomSettings = {
        ...window.intercomSettings,
        name: user.name,
        email: user.email,
        created_at: user.created_at,
      };

      if (window.Intercom) {
        window.Intercom('update', window.intercomSettings);
      }
    },
  },
};
</script>
