import Vue from 'vue';

Vue.mixin({
  resource(value, config) {
    return {
      data: value,
      error: null,
      loading: false,
      loaded: false,
      ...config,
      setLoading(state = true) {
        this.loading = state;
      },
      setValue(value) {
        this.data = value;
      },
    };
  },
  methods: {
    copyText(text) {
      const input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);

      input.select();
      alert('copied');

      document.execCommand('copy');
      document.body.removeChild(input);
    },
  },
});
