export var admin = process.env.VUE_APP_ADMIN_PATH;
export var corporate = process.env.VUE_APP_CORPORATE_PATH;
export var customer = process.env.VUE_APP_CUSTOMER_PATH;
export const accountTypes = `${admin}|${corporate}|${customer}`;

export function path(uri, ...types) {
  if (types.indexOf('*') > -1) {
    types = [admin, corporate, customer];
  }

  var url = `/:accountType(${types.join('|')})/${uri}`;

  return url;
}
